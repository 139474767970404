import {useState, useEffect} from 'react';
import {useForm} from 'react-hook-form';

// animations
import {motion} from 'framer-motion';
import {pageAnimation} from '../components/animations';

// components
import ScrollToTop from '../components/ScrollToTop';
import BetterFileUploader from '../components/BetterFileUploader';
import PersonalData from '../components/PersonalData';
import {ADDRESS} from "../constants/routes";
import axios from "axios";
import {useParams} from "react-router";
import {useSearchParams} from "react-router-dom";
import img from "../assets/images/cart2.svg";
import doneImg from "../assets/images/done.png";
import {HashLink as Link} from "react-router-hash-link";
import * as ROUTES from "../constants/routes";
import errorImg from "../assets/images/error.png";

const NutricniAnamneza = () => {
    const [photos, setPhotos] = useState([]);
    const [results, setResults] = useState([]);
    const [succMsg, setSuccMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [dataFromStorage, setDataFromStorage] = useState({});
    const [showPersonalData, setShowPersonalData] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [usedForm, setUsedForm] = useState(null);
    const importFilesToState = files => {
        setPhotos(files);
    };
    const importFilesToStateOther = files => {
        setResults(files);
    };
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        handleSubmit,
        register,
        reset,
        watch,
        setValue,
        formState: {errors},
    } = useForm();
    const onSubmit = data => {

        const formData = new FormData();
        setDisabled(true);
        for (let item in data) {
            formData.append(item, data[item]);
        }

        formData.append("token", searchParams.get("id"));
        if (photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                formData.append('photos[]', photos[i]);
            }
        }
        if (results.length > 0) {
            for (let i = 0; i < results.length; i++) {
                formData.append('results[]', results[i]);
            }
        }

        axios({
            method: "post",
            url: ADDRESS + '/save/anamneza',
            data: formData,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).catch(err => {
            if (err) {
                setErrorMsg('Anamnézu se nepodařilo odeslat');
                setDisabled(false);
            }
        })
            .then(response => {
                if (response) {
                    setSuccMsg('Anamnéza byla úspěšně odeslána');
                    setDisabled(false);
                    // reset();
                    sessionStorage.removeItem('anamnesis');
                }
            });
        setTimeout(() => {
            setSuccMsg('');
            setErrorMsg('');

            if (usedForm === true) {
                window.location.replace("/")

            }

        }, 5000);
    };

    // ukladani do localStorage
    useEffect(() => {
        const subscription = watch(value =>
            localStorage.setItem('anamnesis', JSON.stringify(value)),
        );
        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {

        axios.get(ADDRESS + "/fetch-row/access_nutrition/" + searchParams.get("id")
        ).then((data) => {
            setUsedForm(data.data);
        })

    }, [disabled]);

    useEffect(() => {
        if (localStorage.getItem('anamnesis')) {
            setDataFromStorage(JSON.parse(localStorage.getItem('anamnesis')));
        }
    }, []);

    // ziskani dat z localStorage
    useEffect(() => {
        if (dataFromStorage) {
            Object.entries(dataFromStorage).forEach(element => {
                setValue(element[0], element[1], {shouldValidate: true});
            });
        }
        if (localStorage.getItem('photos')) {
            setPhotos(localStorage.getItem('photos'));
        }
        if (localStorage.getItem('results')) {
            setResults(localStorage.getItem('results'));
        }
    }, [dataFromStorage]);

    let formik = (<div className="xl:pb-24 lg:pb-12 pt-6 pb-12 xl:px-24 lg:px-16 ">
        <div className="nutricni-anamneza-wrapper flex flex-col justify-center">
            <h1 className="mx-auto">Nutriční anamnéza</h1>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="my-10 flex flex-col gap-8"
            >
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Základní údaje</h2>
                        <div className="flex flex-col md:gap-5 gap-2 boc">
                            <div className="flex lg:flex-row flex-col md:gap-5 gap-2">
                                <div className="lg:w-1/4 w-full">
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Jméno:"
                                        className="p-2 w-full"
                                        {...register('name', {required: false})}
                                    />
                                    {errors.name && errors.name.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="lg:w-1/4 w-full">
                                    <select
                                        name="gender"
                                        className=" p-2 w-full"
                                        {...register('gender', {required: false})}
                                    >
                                        <option value="Muž">Muž</option>
                                        <option value="Žena">Žena</option>
                                    </select>
                                </div>
                                <div className="lg:w-1/4 w-full">
                                    <input
                                        type="number"
                                        name="old"
                                        placeholder="Věk:"
                                        className="p-2 w-full"
                                        {...register('old', {required: true})}
                                    />
                                    {errors.old && errors.old.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                                <div className="lg:w-1/4 w-full">
                                        <div className="w-full">
                                            <p>Chci jídelníček</p>

                                            <div className="flex gap-3 items-center">
                                                <label className="cursor-pointer souhlas">
                                                    <input
                                                        value={"Striktní"}
                                                        type="radio"
                                                        {...register('dietType', {required: true})}
                                                    />
                                                    Striktní
                                                </label>
                                                <label className="cursor-pointer souhlas">
                                                    <input
                                                        value={"Variabilní"}
                                                        type="radio"
                                                        {...register('dietType', {required: true})}
                                                        checked
                                                    />
                                                    Variabilní
                                                </label>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex lg:flex-row flex-col md:gap-5 gap-2 items-start">
                                <div className="lg:w-1/3 w-full flex items-center">
                                    <div className="w-full">
                                        <input
                                            type="number"
                                            name="weight"
                                            placeholder="Váha v kg:"
                                            className="p-2 w-full"
                                            {...register('weight', {required: true})}
                                        />
                                        {errors.weight && errors.weight.type === 'xxx' && (
                                            <div className="md:h-9 h-6">
                                                <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                    Toto pole je povinné!
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="lg:w-1/3 w-full flex items-center">
                                    <div className="w-full">
                                        <input
                                            type="number"
                                            name="height"
                                            placeholder="Výška v cm:"
                                            className="w-full p-2"
                                            {...register('height', {required: true})}
                                        />
                                        {errors.height && errors.height.type === 'xxx' && (
                                            <div className="md:h-9 h-6">
                                                <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                    Toto pole je povinné!
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="lg:w-1/3 w-full flex items-center">
                                    <div className="w-full">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="Email:"
                                            className="w-full p-2"
                                            {...register('email', {required: true})}
                                        />
                                        {errors.email && errors.email.type === 'xxx' && (
                                            <div className="md:h-9 h-6">
                                                <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                    Toto pole je povinné!
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Fitness cíle a zkušenosti</h2>
                        <div className="flex flex-col md:gap-4 gap-3">
                            <div className="flex flex-col gap-2">
                                <span>Jak se vyvíjela vaše váha od&nbsp;dětství?</span>
                                <textarea
                                    name="weight-development"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weightDevelopment', {required: false})}
                                />
                                {errors.weightDevelopment &&
                                errors.weightDevelopment.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
										<span>
											Na&nbsp;jakou váhu byste se chtěli dostat? Jaký máte cíl?
										</span>
                                <textarea
                                    name="target-weight"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('targetWeight', {required: false})}
                                />
                                {errors.targetWeight &&
                                errors.targetWeight.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Jaká je vaše motivace? Proč to chcete změnit?</span>
                                <textarea
                                    name="motivation"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('motivation', {required: false})}
                                />
                                {errors.motivation &&
                                errors.motivation.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
										<span>
											Máte za&nbsp;sebou nějaké vyzkoušené diety?
											(Fungovaly/nefungovaly?)
										</span>
                                <textarea
                                    name="diets"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('diets', {required: false})}
                                />
                                {errors.diets && errors.diets.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-0.5">
                                    <span>Jaký byste řekli, že jste somatotyp?</span>
                                    <span className="small-span">
												Endo&nbsp;-&nbsp;snadno nabírá, ecto&nbsp;-&nbsp;těžko
												nabírá, meso&nbsp;-&nbsp;něco mezi
											</span>
                                </div>
                                <select
                                    className="p-2"
                                    name="somatotype"
                                    {...register('somatotype', {required: false})}
                                >
                                    <option value="Ektomorf">Ektomorf</option>
                                    <option value="Mesomorf">Mesomorf</option>
                                    <option value="Endomorf">Endomorf</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Zaměstnání</h2>
                        <div className="flex flex-col md:gap-4 gap-3">
                            <div className="flex flex-col gap-2">
										<span>
											Popiště vaše zaměstnání (sedavé, běhavé, fyzicky náročné,
											kolik hodin denně,&nbsp;...)
										</span>
                                <textarea
                                    name="job"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('job', {required: false})}
                                />
                                {errors.job && errors.job.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Pracujete na&nbsp;vícesměnný/noční provoz?</span>
                                <textarea
                                    name="night-shift"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('nightShift', {required: false})}
                                />
                                {errors.nightShift &&
                                errors.nightShift.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
										<span>
											Máte v&nbsp;práci možnost dodržovat pravidelnost
											v&nbsp;jídle?
										</span>
                                <textarea
                                    name="food-regularity"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('foodRegularity', {required: false})}
                                />
                                {errors.foodRegularity &&
                                errors.foodRegularity.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Léky a&nbsp;alergie</h2>
                        <div className="flex flex-col md:gap-4 gap-3">
                            <div className="flex flex-col gap-2">
                                <span>Máte nějakou potravinovou alergii?</span>
                                <textarea
                                    name="food-allergy"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('foodAllergy', {required: false})}
                                />
                                {errors.foodAllergy &&
                                errors.foodAllergy.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Je nějaké jídlo, po kterém vám není dobře?</span>
                                <textarea
                                    // english lvl.56494654646
                                    name="bad-food"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('badFood', {required: false})}
                                />
                                {errors.badFood && errors.badFood.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Berete nějaké léky?</span>
                                <textarea
                                    name="medicaments"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('medicaments', {required: false})}
                                />
                                {errors.medicaments &&
                                errors.medicaments.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-1">
                                    <span>Berete nějaké doplňky stravy? Jaké?</span>
                                    <span className="small-span">
												Napište dávkování, značku, kdy suplement berete
												a&nbsp;jak pravidelně
											</span>
                                </div>
                                <textarea
                                    name="supplements"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('supplements', {required: false})}
                                />
                                {errors.supplements &&
                                errors.supplements.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Zdraví a pohyb</h2>
                        <div className="flex flex-col md:gap-4 gap-3">
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-0.5">
                                    <span>Trápí vás nějaký zdravotní problém?</span>
                                    <span className="small-span">
												Únava, nespavost, pálení žáhy, žaludeční problémy,
												vředy, nadýmání, zácpa, průjem, časté bolesti hlavy,
												krevní tlak (vysoký, nízký, kolísavý), osteoporóza,
												astma, autismus, schizofrenie, epilepsie,
												diabetes/prediabetes, zvýšený cholesterol, bolesti zad,
												bolesti krční páteře, žlučník, játra, ledviny, slinivka,
												padání vlasů, lámání nehtů
											</span>
                                </div>
                                <textarea
                                    name="health-issue"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('healthIssue', {required: false})}
                                />
                                {errors.healthIssue &&
                                errors.healthIssue.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
										<span>
											Otázky pro ženy: Máte pravidelnou menstruaci? Berete
											hormonální antikoncepci?
										</span>
                                <textarea
                                    name="menstruation"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('menstruation', {required: false})}
                                />
                                {errors.menstruation &&
                                errors.menstruation.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-0.5">
                                    <span>Pohybová aktivita</span>
                                    <span className="small-span">Kolikrát týdně a co?</span>
                                </div>
                                <textarea
                                    name="activity"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('activity', {required: false})}
                                />
                                {errors.activity && errors.activity.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-0.5">
                                    <span>Spánek</span>
                                    <span className="small-span">
												Usínáte dobře? Chodíte spát ve&nbsp;stejnou dobu
												a&nbsp;vstáváte ve&nbsp;stejnou dobu? Budíte se
												v&nbsp;noci? Kolik hodin spíte?
											</span>
                                </div>
                                <textarea
                                    name="sleep"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('sleep', {required: false})}
                                />
                                {errors.sleep && errors.sleep.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Životospráva</h2>
                        <div className="flex flex-col md:gap-4 gap-3">
                            <div className="flex flex-col gap-2">
                                <span>Kouření</span>
                                <textarea
                                    name="smoking"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('smoking', {required: false})}
                                />
                                {errors.smoking && errors.smoking.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Alkohol</span>
                                <textarea
                                    name="alcohol"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('alcohol', {required: false})}
                                />
                                {errors.alcohol && errors.alcohol.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Káva</span>
                                <textarea
                                    name="coffee"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('coffee', {required: false})}
                                />
                                {errors.coffee && errors.coffee.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Chutě na&nbsp;sladké nebo na&nbsp;slané?</span>
                                <textarea
                                    name="sweet-salty"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('sweetSalty', {required: false})}
                                />
                                {errors.sweetSalty &&
                                errors.sweetSalty.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-0.5">
                                    <span>Pitný režim</span>
                                    <span className="small-span">
												Co pijete a&nbsp;kolik?
											</span>
                                </div>
                                <textarea
                                    name="drinking-regime"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('drinkingRegime', {required: false})}
                                />
                                {errors.drinkingRegime &&
                                errors.drinkingRegime.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-1">Ukázkový jídelníček</h2>
                        <h6 className="mx-auto mb-4">
                            Jak se stravujete ve&nbsp;Váš běžný den nebo vypište jídlo
                            za&nbsp;poslední tři dny
                        </h6>
                        <h3 className="mt-5 mb-3">Všední dny:</h3>
                        <div className="flex flex-col md:gap-4 gap-3 box p-6">
                            <div className="flex flex-col gap-2">
                                <span>Snídaně</span>
                                <textarea
                                    name="weekday-breakfast"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekdayBreakfast', {required: false})}
                                />
                                {errors.weekdayBreakfast &&
                                errors.weekdayBreakfast.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Dopolední svačina</span>
                                <textarea
                                    name="weekday-snack"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekdaySnack', {required: false})}
                                />
                                {errors.weekdaySnack &&
                                errors.weekdaySnack.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Oběd</span>
                                    <textarea
                                        name="weekday-lunch"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayLunch', {required: false})}
                                    />
                                    {errors.weekdayLunch &&
                                    errors.weekdayLunch.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Odpolední svačina</span>
                                    <textarea
                                        name="weekday-afternoon-snack"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayAfternoonSnack', {
                                            required: false,
                                        })}
                                    />
                                    {errors.weekdayAfternoonSnack &&
                                    errors.weekdayAfternoonSnack.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Večeře</span>
                                    <textarea
                                        name="weekday-dinner"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayDinner', {required: false})}
                                    />
                                    {errors.weekdayDinner &&
                                    errors.weekdayDinner.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Druhá večeře/večerní mlsání</span>
                                    <textarea
                                        name="weekday-second-dinner"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdaySecondDinner', {required: false})}
                                    />
                                    {errors.weekdaySecondDinner &&
                                    errors.weekdaySecondDinner.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 md:mt-16 mt-10 box p-6">
                            <div className="flex flex-col gap-2">
                                <span>Snídaně</span>
                                <textarea
                                    name="weekday-breakfast-2"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekdayBreakfast2', {required: false})}
                                />
                                {errors.weekdayBreakfast2 &&
                                errors.weekdayBreakfast2.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Dopolední svačina</span>
                                <textarea
                                    name="weekday-snack-2"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekdaySnack2', {required: false})}
                                />
                                {errors.weekdaySnack2 &&
                                errors.weekdaySnack2.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Oběd</span>
                                    <textarea
                                        name="weekday-lunch-2"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayLunch2', {required: false})}
                                    />
                                    {errors.weekdayLunch2 &&
                                    errors.weekdayLunch2.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Odpolední svačina</span>
                                    <textarea
                                        name="weekday-afternoon-snack-2"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayAfternoonSnack2', {
                                            required: false,
                                        })}
                                    />
                                    {errors.weekdayAfternoonSnack2 &&
                                    errors.weekdayAfternoonSnack2.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Večeře</span>
                                    <textarea
                                        name="weekday-dinner-2"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdayDinner2', {required: false})}
                                    />
                                    {errors.weekdayDinner2 &&
                                    errors.weekdayDinner2.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Druhá večeře/večerní mlsání</span>
                                    <textarea
                                        name="weekday-second-dinner-2"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekdaySecondDinner2', {
                                            required: false,
                                        })}
                                    />
                                    {errors.weekdaySecondDinner2 &&
                                    errors.weekdaySecondDinner2.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <h3 className="md:mt-16 mt-10 mb-3">Víkend:</h3>
                        <div className="flex flex-col md:gap-4 gap-3 box p-6">
                            <div className="flex flex-col gap-2">
                                <span>Snídaně</span>
                                <textarea
                                    name="weekend-breakfast"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekendBreakfast', {required: false})}
                                />
                                {errors.weekendBreakfast &&
                                errors.weekendBreakfast.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <span>Dopolední svačina</span>
                                <textarea
                                    name="weekend-snack"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('weekendSnack', {required: false})}
                                />
                                {errors.weekendSnack &&
                                errors.weekendSnack.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Oběd</span>
                                    <textarea
                                        name="weekend-lunch"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekendLunch', {required: false})}
                                    />
                                    {errors.weekendLunch &&
                                    errors.weekendLunch.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Odpolední svačina</span>
                                    <textarea
                                        name="weekend-afternoon-snack"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekendAfternoonSnack', {
                                            required: false,
                                        })}
                                    />
                                    {errors.weekendAfternoonSnack &&
                                    errors.weekendAfternoonSnack.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Večeře</span>
                                    <textarea
                                        name="weekend-dinner"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekendDinner', {required: false})}
                                    />
                                    {errors.weekendDinner &&
                                    errors.weekendDinner.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-2">
                                    <span>Druhá večeře/večerní mlsání</span>
                                    <textarea
                                        name="weekend-second-dinner"
                                        className="w-full p-2"
                                        placeholder="Napište..."
                                        {...register('weekendDinner2', {required: false})}
                                    />
                                    {errors.weekendDinner2 &&
                                    errors.weekendDinner2.type === 'xxx' && (
                                        <div className="md:h-9 h-6">
                                            <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                                Toto pole je povinné!
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">Neoblíbená jídla</h2>
                        <div className="flex flex-col gap-4">
                            <div className="flex flex-col gap-2">
										<span>
											Jaké potraviny nemají být v&nbsp;jídelníčku zařazeny? Co
											Vám nechutná?
										</span>
                                <span className="small-span">
											Ryby, fazole, zakysané mléčné výrobky,&nbsp;…
										</span>
                                <textarea
                                    name="unpopular-food"
                                    className="w-full p-2"
                                    placeholder="Napište..."
                                    {...register('unpopularFood', {required: false})}
                                />
                                {errors.unpopularFood &&
                                errors.unpopularFood.type === 'xxx' && (
                                    <div className="md:h-9 h-6">
                                        <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                            Toto pole je povinné!
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-1">Fotky</h2>
                        <h6 className="mx-auto mb-6 text-center">
                            Pokud máte fotku ve&nbsp;spodním prádle a&nbsp;nestydíte se ji
                            zaslat, připojte ji k&nbsp;emailu. Pomůže mi to vyhodnotit Váš
                            typ postavy. Následně fotka bude sloužit hlavně pro&nbsp;Vás
                            pro&nbsp;budoucí porovnání a&nbsp;motivaci. Ideální je mít
                            fotku zepředu, z&nbsp;boku, zezadu.
                        </h6>
                        <BetterFileUploader
                            text={'Vyberte Vaše fotky nebo je sem přetáhněte.'}
                            importFilesToState={importFilesToState}
                            startFiles={[]}
                            onlyOne={false}
                            deleteFun={null}
                            checkYT={null}
                            deleteAble={false}
                            type={"fotky"}
                        />
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-1">Výsledky měření</h2>
                        <h6 className="mx-auto mb-6 text-center">
                            Pokud máte výsledky měření z&nbsp;InBody, Tanity či jiného
                            bioimpedačního zařízení (podkožní tuk, svaly, vnitřní tuk,
                            voda), zašlete foto výsledků.
                        </h6>
                        <BetterFileUploader
                            text={'Vyberte fotky měření nebo je sem přetáhněte.'}
                            importFilesToState={importFilesToStateOther}
                            startFiles={[]}
                            onlyOne={false}
                            deleteFun={null}
                            checkYT={null}
                            deleteAble={false}
                            type={"měření"}
                        />
                    </div>
                </div>
                <div className="form-container relative">
                    <div className="line w-24 h-2 mx-auto"/>
                    <div className="pt-8 pb-12 px-6">
                        <h2 className="mx-auto mb-4">BMI</h2>
                        <div className="flex flex-col gap-3">
                            <div
                                className="flex md:flex-row flex-col gap-2 md:items-center items-start justify-start">
                                <h3 className="bmi">BMR</h3>
                                <h6>
                                    minimum, co potřebuje Vaše tělo v&nbsp;klidovém stavu
                                </h6>
                            </div>
                            <div
                                className="flex md:flex-row flex-col gap-2 md:items-center items-start justify-start">
                                <h3 className="bmi">Denní spotřeba kalorií </h3>
                                <h6>+ fyzická aktivita přes den</h6>
                            </div>
                            <div
                                className="flex md:flex-row flex-col gap-2 md:items-center items-start justify-start">
                                <h3 className="bmi">BMI</h3>
                                <h6>
                                    index, který ukazuje, kde se v&nbsp;tabulce pohybujeme,
                                    u&nbsp;sportovců a&nbsp;lidí s&nbsp;vyšší svalovou hmotou
                                    na&nbsp;to nekoukám, ale počítám pro zajímavost
                                </h6>
                            </div>
                        </div>
                        <div className="table w-full mt-5">
                            <table>
                                <thead>
                                <tr>
                                    <th>BMI</th>
                                    <th>Kategorie</th>
                                    <th>Zdravotní rizika</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Méně než 18,5</td>
                                    <td>Podváha</td>
                                    <td>Zdravotní rizika</td>
                                </tr>
                                <tr>
                                    <td>18,5 - 24,9</td>
                                    <td>Norma</td>
                                    <td>Minimální</td>
                                </tr>
                                <tr>
                                    <td>25 - 29,9</td>
                                    <td>Nadváha</td>
                                    <td>Střední</td>
                                </tr>
                                <tr>
                                    <td>30 - 34,9</td>
                                    <td>Obezita 1.&nbsp;stupně</td>
                                    <td>Zvýšená</td>
                                </tr>
                                <tr>
                                    <td>35 - 39,9</td>
                                    <td>Obezita 2.&nbsp;stupně</td>
                                    <td>Vysoká</td>
                                </tr>
                                <tr>
                                    <td>40 a více</td>
                                    <td>Obezita 3.&nbsp;stupně</td>
                                    <td>Velmi vysoká</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" mb-10">
                        <div className="flex gap-3 items-center">
                            <input
                                type="checkbox"
                                {...register('personalData', {required: true})}
                            />
                            <span
                                className="cursor-pointer souhlas"
                                onClick={() => {
                                    setShowPersonalData(true);
                                }}
                            >
										Souhlasím se&nbsp;zpracováním osobních údajů
									</span>
                        </div>
                        {errors.personalData &&
                        errors.personalData.type === 'required' && (
                            <div className="md:h-9 h-6">
                                <p className="h-6 text-left text-red-500 mb-1 err leading-6">
                                    Potvrďte souhlas!
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center w-full">
                        <input
                            type="submit"
                            value={'Odeslat formulář'}
                            className="px-3 py-2 uppercase cursor-pointer"
                            disabled={disabled}
                        />
                    </div>
                </div>
                {succMsg !== '' ? (
                    <div className="w-full mt-9">
                        <p
                            className="h-6 text-green-500 my-3 text-center font-normal "
                            style={{color: '#48bb78'}}
                        >
                            {succMsg}
                        </p>
                    </div>
                ) : (
                    ''
                )}
                {errorMsg !== '' ? (
                    <div className="w-full mt-9">
                        <p className="h-6 text-red-500 my-3 text-center font-normal">
                            {errorMsg}
                        </p>
                    </div>
                ) : (
                    ''
                )}
            </form>
        </div>
    </div>);

    return (
        <motion.div
            variants={pageAnimation}
            initial="hidden"
            animate="enter"
            exit="exit"
            transition={{type: 'linear'}}
            className="text-white z-1 nutricni-anamneza md:pt-48 pt-36 px-4"
        >
            <ScrollToTop/>

            {
                usedForm !== null ? (
                    !usedForm ? formik :
                        <div className="text-center prazdnyKosik md:px-44 px-16 lg:py-16 py-6 rounded-xl my-24">
                            <img src={doneImg} alt="done" className="lg:h-32 h-24 mx-auto"/>
                            <h1 className="mt-8 mb-4">Nutriční anamnéza byla úspěšně odeslána.</h1>

                        </div>
                ) : <div className="flex items-center justify-center ">
                    <div className="w-40 h-40 border-t-4 border-b-4 border-red-900 rounded-full animate-spin"/>
                </div>
            }

            <PersonalData
                setShowPersonalData={setShowPersonalData}
                showPersonalData={showPersonalData}
            />
        </motion.div>
    );
};

export default NutricniAnamneza;
