import tick from '../assets/images/tick.png';
import cart from '../assets/images/cart.png';

import {Link, useSearchParams} from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import {useState} from "react";

const Services = props => {
    const [searchParams, setSearchParams] = useSearchParams();

    const servicesData = [
        {
            id: 1,
            title: 'Trénink s koněm',
            price: '800 Kč',
            image: 'https://api.jankrasinsky.cz/poukaz1.png',
            li: [
                {
                    id: 1,
                    text: 'Doba 90-120 minut (1 lekce)',
                },
                {
                    id: 2,
                    text: 'Rozbor cviků podle klienta',
                },
                {
                    id: 3,
                    text: 'Zhodnocení základních cviků a doporučení',
                },
            ],
        },
        {
            id: 2,
            title: 'Tréninky s koněm (10 lekcí)',
            price: '7 000 Kč',
            image: 'https://api.jankrasinsky.cz/poukaz10.png',
            li: [
                {
                    id: 1,
                    text: 'Doba 90-120 minut',
                },
                {
                    id: 2,
                    text: 'Rozbor cviků podle klienta',
                },
                {
                    id: 3,
                    text: 'Zhodnocení základních cviků a doporučení',
                },
                {
                    id: 4,
                    text: 'Sestavení popřípadě změna tréninkové jednotky',
                },
            ],
        },
        {
            id: 3,
            title: 'Stravovací plán',
            price: '3 000 Kč',
            image: '',
            li: [
                {
                    id: 1,
                    text: 'Nutriční plán na míru',
                },
                {
                    id: 2,
                    text: 'Výběr variabilního nebo striktního jídelníčku',
                },
                {
                    id: 3,
                    text: 'Součástí  stravovací a vzdělávací protokol',
                },
                {
                    id: 4,
                    text: 'Zahrnuje konzultaci + následné úpravy',
                },
            ],
        },
        {
            id: 4,
            title: 'Stravovací plán',
            price: '1 Kč',
            image: '',
            li: [
                {
                    id: 1,
                    text: 'Nutriční plán na míru',
                },
                {
                    id: 2,
                    text: 'Výběr variabilního nebo striktního jídelníčku',
                },
                {
                    id: 3,
                    text: 'Součástí  stravovací a vzdělávací protokol',
                },
                {
                    id: 4,
                    text: 'Zahrnuje konzultaci + následné úpravy',
                },
            ],
        },
    ];

    const cartHandler = item => {
        props.setCartErr('');

        let products = [];
        let product = {
            id: item.id,
            title: item.title,
            quantity: 1,
            image: item.image,
            price: (item.price).replace(" ", "").replace("Kč", "").replace(" ", "")
        }
        products.push(product);

        let duplicity = false;
        if (sessionStorage.getItem("products") === null) {
            sessionStorage.setItem('products', JSON.stringify(products));
        } else {
            let actual = JSON.parse(sessionStorage.getItem('products'));
            for (let i = 0; i < actual.length; i++) {
                if (actual[i].title === item.title) {
                    actual[i].quantity = actual[i].quantity + 1;
                    duplicity = true;
                }
            }

            if (!duplicity) {
                actual.push(product);
            }

            props.setItems(actual.length);
            return sessionStorage.setItem('products', JSON.stringify(actual));

        }
    };

    return (
        <div className="services section px-5" id="sluzby">
            <div className="md:py-24 py-12 text-center">
                <h1 className="uppercase font-semibold md:mb-5 mb-2">Služby</h1>
                <div className="line md:w-24 w-16 h-1 mx-auto md:mb-16 mb-10"></div>
                <div className="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-7 2xl:w-full mx-auto">
                    {servicesData.map(item => {
                        const {id, title, price, li} = item;
                        if (id === 4) {
                            console.log(searchParams.get("id") == 4)
                            if (searchParams.get("id") == 4) {

                            } else {
                                return false;
                            }
                        }
                        return (
                            <div
                                className="w-full item py-10 md:px-16 px-4 flex flex-col justify-between overflow-hidden"
                                key={id}
                            >
                                <div
                                    data-aos="fade-right"
                                    data-aos-easing="linear"
                                    data-aos-duration="700"
                                    data-aos-delay={id * 250}
                                    className="flex flex-col justify-between h-full"
                                >
                                    <div>
                                        <h1>{title}</h1>
                                        <h2 className="mt-4 mb-6">{price}</h2>
                                    </div>
                                    <ul className="text-left">
                                        {li.map(item => {
                                            const {id, text} = item;
                                            return (
                                                <li
                                                    className="flex items-center gap-x-2"
                                                    key={id}
                                                >
                                                    <img src={tick} alt="tick"/>
                                                    <span>{text}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="mt-9">
                                        <Link to={ROUTES.KOSIK}>
                                            <button
                                                className="flex items-center gap-x-3 rounded-xl px-4 py-3 mx-auto"
                                                onClick={() => cartHandler(item)}
                                            >
                                                <img
                                                    src={cart}
                                                    alt="kosik"
                                                    className="w-6"
                                                />
                                                <span className="uppercase font-medium">
																			objednat
																		</span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Services;
